import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import namkeenPare from '../jainnamkin/jain shree products edited/namkeen pare.png';
import daalMoth from '../jainnamkin/jain shree products edited/daal moth.png';
import sevSpicy from '../jainnamkin/jain shree products edited/sev- spicy.png';
import khataMixture from '../jainnamkin/jain shree products edited/khatta-meetha mixture.png'
import testynutss from '../jainnamkin/jain shree products edited/tasty nuts.png'
import Chipsblack from  '../jainnamkin/jain shree products edited/chips- black pepper.png'

const _items = [
  {
    title: 'Namkeen Pare',
    desc: 'TRY NOW',
    image: namkeenPare, // Replace with actual image path
  },
  {
    title: 'Daal Moth',
    desc: 'TRY NOW',
    image: daalMoth, // Replace with actual image path
  },
  {
    title: 'Sev Spicy',
    desc: 'TRY NOW',
    image: sevSpicy, // Replace with actual image path
  },
  {
    title: 'Khatta Meetha Mixture',
    desc: 'TRY NOW',
    image: khataMixture, // Replace with actual image path
  },
  {
    title: 'Tasty Nutss',
    desc: 'TRY NOW',
    image: testynutss, // Replace with actual image path
  },
  {
    title: 'Chips Black Pepper',
    desc: 'TRY NOW',
    image: Chipsblack, // Replace with actual image path
  },
];

function Carousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Default number of slides to show
    slidesToScroll: 3, // Number of slides to scroll
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // Show 2 slides on medium screens
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Show 1 slide on small screens
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        {_items.map((item, index) => (
          <div key={index} className="item">
            <div className="btc_team_slider_cont_main_wrapper">
              <div className="btc_team_img_wrapper">
                <img src={item.image} alt={item.title} />
                <div className="btc_team_social_wrapper">
                  <h5>{item.desc}</h5>
                </div>
              </div>
              <div className="btc_team_img_cont_wrapper">
                <h4>
                  <a href="#">{item.title}</a>
                </h4>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Carousel;
