import React from 'react';
import SnackItem from './NamkinItem';
import { useDispatch, useSelector } from 'react-redux';
import { add, updateQuantity } from '../redux/CartSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ImCheckmark } from "react-icons/im";
import chakli from './jainnamkin/jain shree products edited/chakli.png';
import chipsBlackPepper from './jainnamkin/jain shree products edited/chips- black pepper.png';
import gathiye from './jainnamkin/jain shree products edited/gathiye.png';

const data = [
  {
    id: 2,
    title: "Chakli",
    price: { "250g": 120, "500g": 240, "1kg": 480 },
    image: chakli,
    description: "Aenean sollicitudin, lorem quis",
  },
  {
    id: 6,
    title: "Chips Black Pepper",
    price: { "250g": 85, "500g": 170, "1kg": 340 },
    image: chipsBlackPepper,
    description: "Aenean sollicitudin, lorem quis",
  },
  {
    id: 12,
    title: "Gathiye",
    price: { "250g": 90, "500g": 180, "1kg": 360 },
    image: gathiye,
    description: "Aenean sollicitudin, lorem quis",
  },
];

const SnacksList = () => {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const handleAdd = (item) => {
    const itemWithWeightId = item.id + "-" + item.selectedWeight; // Combine product id with weight

    // Check if the item with the specific weight is already in the cart
    const itemInCart = cart.find((cartItem) => cartItem.id === itemWithWeightId);

    if (itemInCart) {
      // If item already in cart, update its quantity for this specific weight
      dispatch(updateQuantity({ id: itemWithWeightId, quantity: itemInCart.quantity + 1 }));
    } else {
      // If item is not in cart, add it as a new product with selected weight
      dispatch(add({ ...item, id: itemWithWeightId, quantity: 1 }));
    }

    toast.success("Cart updated successfully!", {
      position: "bottom-right",
      style: { backgroundColor: "orange", color: "white" },
      icon: <span style={{ color: "white", marginRight: '10px' }}><ImCheckmark color='white' /></span>,
    });
  };

  return (
    <div className="ck_bc_main_wrapper float_left">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="ck_heading_new_center_wrapper float_left">
              <h4>Our Products</h4>
              <h3>Namkeen</h3>
            </div>
          </div>

          {data.map((item, index) => (
            <SnackItem key={index} item={item} handleAdd={handleAdd} />
          ))}

          <ToastContainer />

        </div>
      </div>
    </div>
  );
};

export default SnacksList;
