import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2';
import BeatLoader from "react-spinners/BeatLoader";
const CheckoutPage = () => {
  const [billingDetails, setBillingDetails] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    phone: '',
    country: 'india',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postcode: '',
    createAccount: false,
    password: '',
  });

  const productitem = useSelector((state) => state.cart);
  const [isLoading, setIsLoading] = useState(false); // Loader state

  const totalAmount = productitem.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setBillingDetails({
      ...billingDetails,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;

      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);

      document.body.appendChild(script);
    });
  };

  const createRazorpayOrder = async (amount) => {
    setIsLoading(true); // Start loader
    try {
      const { data } = await axios.post(
        'https://jainshree-nakin-hotel-1.onrender.com/api/payment/orders',
        { amount: amount * 100, currency: 'INR' },
        { headers: { 'Content-Type': 'application/json' } }
      );
      handleRazorpayScreen(data.amount);
    } catch (error) {
      console.error('Error creating order:', error);
      Swal.fire('Error', 'Failed to create Razorpay order.', 'error');
    } finally {
      setIsLoading(false); // Stop loader
    }
  };

  const handleRazorpayScreen = async (amount) => {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
    if (!res) {
      Swal.fire('Error', 'Failed to load Razorpay SDK.', 'error');
      return;
    }

    const options = {
      key: 'rzp_test_2QNAVoN2x0Npk8',
      amount: amount,
      currency: 'INR',
      name: 'Papaya Coders',
      description: 'Payment to Papaya Coders',
      image: 'https://papayacoders.com/demo.png',
      handler: (response) => {
        Swal.fire('Success', 'Payment successful!', 'success');
      },
      prefill: {
        name: 'Papaya Coders',
        email: 'papayacoders@gmail.com',
      },
      theme: {
        color: '#F4C430',
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <div className="checkout-page">
   
      <div className="container">
        <div className="checkout-form">
          <h1>Billing Details</h1>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={billingDetails.firstName}
                  onChange={handleInputChange}
                  placeholder="First Name"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={billingDetails.lastName}
                  onChange={handleInputChange}
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  name="email"
                  value={billingDetails.email}
                  onChange={handleInputChange}
                  placeholder="Email"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Address</label>
                <input
                  type="text"
                  name="address1"
                  value={billingDetails.address1}
                  onChange={handleInputChange}
                  placeholder="Address"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>PIN CODE</label>
                <input
                  type="number"
                  name="address1"
                  value={billingDetails.address1}
                  onChange={handleInputChange}
                  placeholder="Address"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>CITY</label>
                <input
                  type="text"
                  name="address1"
                  value={billingDetails.address1}
                  onChange={handleInputChange}
                  placeholder="Address"
                />
              </div>
            </div>
    
          </div>
        </div>

        <div className="order-box">
          <h1>Your Order</h1>
          <ul>
            <li>Sub Total: ₹{totalAmount}</li>
            <li>Shipping: Free</li>
            <li>Total: ₹{totalAmount}</li>
          </ul>
        </div>
        {isLoading && (
          <BeatLoader

          color='#FFB74E'
          loading={isLoading}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )}
        <div className="text-right">
          <button
            className="place-order-btn"
            onClick={() => createRazorpayOrder(totalAmount)}
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : 'Place Order'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
