import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Header from './COMPONENT/Header';
import '../src/COMPONENT/css/animate.css';
import '../src/COMPONENT/css/bootstrap.css';
import '../src/COMPONENT/css/flatpicker-min.css';
import '../src/COMPONENT/css/magnific-popup.css';
import '../src/COMPONENT/css/spacing.css';
import '../src/COMPONENT/css/swiper-bundle.css';
import Home from './COMPONENT/Home';
import RoomDetails from './COMPONENT/RoomDetails';
import RoomSlider from './COMPONENT/Swiper';
import LoginForm from './COMPONENT/LOGIN/LoginPage';
import RegisterForm from './COMPONENT/RegisterPage';
import { Dataprovider } from './HOOKS/Context';
import Footer from './COMPONENT/Footer';
import AllRooms from './COMPONENT/AllRooms';
import HomeNam from './JAINSHREE/Home';
import MainHome from './COMPONENT/MainHome';
import Cart from './JAINSHREE/Cart/Cart';
import SendMessage from './COMPONENT/Swipe';
import Profile from './COMPONENT/Profile';
import Adminpanel from './COMPONENT/Adminpanel';
import Payment from './JAINSHREE/Payment';
import NakinFooter from './JAINSHREE/Footer';
import Navbar from './JAINSHREE/Navbar';
import Product from './JAINSHREE/Product';
import ContactDetails from './JAINSHREE/ContactDetails';
import jainshreecontact from './JAINSHREE/JainshreeCont';
import Category from './JAINSHREE/CategoryProdcut'
import Checkout from './JAINSHREE/CheckOut'
import SweetsCategory from './JAINSHREE/Sweetscategory';
import roomdetailsone from './COMPONENT/roomdetailsone';
import GallerSec from './COMPONENT/GallerSec';
import HotelContect from './COMPONENT/HotelContect';
import ScrollToTop from "./ScrollToTop.js";

// Layout component that conditionally renders Header and Footer based on the route
function Layout({ children }) {
  const location = useLocation();

  const hideHeaderRoutes = ['/Namkeen', '/cart', '/Products', '/jainshreecontact', '/Category', '/checkout', '/SweetsCategory', '/'];
  const showNakinFooterRoutes = ['/Namkeen', '/cart', '/Products', '/jainshreecontact', '/Category', '/checkout', '/SweetsCategory'];
  const showNavbarRoutes = ['/Namkeen', '/cart', '/Products', '/jainshreecontact', '/Category', '/checkout', '/SweetsCategory'];

  const shouldShowHeader = !hideHeaderRoutes.includes(location.pathname);
  const shouldShowNakinFooter = showNakinFooterRoutes.includes(location.pathname);
  const shouldShowFooter = !['/', ...showNakinFooterRoutes].includes(location.pathname); // Exclude `/` (MainHome) from showing Footer
  const shouldShowNavbar = showNavbarRoutes.includes(location.pathname);

  return (
    <>
      {shouldShowHeader && <Header />}
      {shouldShowNavbar && <Navbar />}
      {children}
      {shouldShowFooter && <Footer />}
      {shouldShowNakinFooter && <NakinFooter />}
    </>
  );
}


function App() {
  return (
    <Dataprovider>
      <Router>
      <ScrollToTop />
        <Layout>
          <Routes>
            <Route path="/" Component={MainHome} />
            <Route path="/HotelHome" Component={Home} />
            <Route path="/Namkeen" Component={HomeNam} />
            <Route path="/RoomDetails" Component={RoomDetails} />
            <Route path="/swiper" Component={RoomSlider} />
            <Route path="/loginuser" Component={LoginForm} />
            <Route path="/registeruser" Component={RegisterForm} />
            <Route path="allrooms" Component={AllRooms} />
            <Route path="/cart" Component={Cart} />
            <Route path="/boo" Component={SendMessage} />
            <Route path="/profile" Component={Profile} />
            <Route path="/admin" Component={Adminpanel} />
            <Route path="/payment" Component={Payment} />
            <Route path='/Products'  Component={Product} />
            <Route path='/contact' Component={ContactDetails}/>
            <Route path='/jainshreecontact' Component={jainshreecontact}/>
            <Route path='/Category' Component={Category}/>
            <Route path='/checkout'  Component={Checkout} />
            <Route path='/SweetsCategory'  Component={SweetsCategory} />
            <Route path='/detailsroom/:id/:arrivalDate/:departureDate' Component={roomdetailsone}/>
            <Route path='/gallerySection' Component={GallerSec}/>
            <Route path='/Contect' Component={HotelContect}/>
           
          </Routes>
        </Layout>
      </Router>
      
    </Dataprovider>
  );
}

export default App;
