import React, { useState } from "react";


const NamkinItem = ({ item, handleAdd }) => {
  const [selectedWeight, setSelectedWeight] = useState("250g");

  // Weight Options
  const weightOptions = [
    { label: "250 Gms", value: "250g" },
    { label: "500 Gms", value: "500g" },
    { label: "1 kg", value: "1kg" }
  ];

  return (
    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
      <div className="ck_elem_slide_img_wrapper">
        <div className="ck_elem_slide_img">
          <img src={item.image} alt={item.title} />
        </div>
        <div className="ck_elem_slide_img_cont">
          <h3>{item.title}</h3>
          <p>{item.description}</p>

          {/* Price & Weight Selection */}
          <div className="price-weight-container">
            <h5 className="price">₹ {item.price[selectedWeight]}</h5>
            <div className="weight-selector">
              {weightOptions.map((option) => (
                <button
                  key={option.value}
                  className={`weight-btn ${selectedWeight === option.value ? "active" : ""}`}
                  onClick={() => setSelectedWeight(option.value)}  // Update selected weight
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>

          {/* Add to Cart Button */}
          <button 
            onClick={() => handleAdd({ ...item, selectedWeight, price: item.price[selectedWeight] })} 
            className="add-to-cart-btn"
          >
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default NamkinItem;
