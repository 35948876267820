import React, { useState } from 'react';
import * as FaIcons from "react-icons/fa"; // Icons
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BsCart3 } from "react-icons/bs";
import { SideNavbarData } from "./SideNavbarData";
import logo from './jainnamkin/web logo jain shree.png';

import "./SideNavbar.css";
import "./css/style.css";
import "./Navbar.css";
import "./HamburgerMenu.css";

function Navbar() {
  const cart = useSelector((state) => state.cart);
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <IconContext.Provider value={{ color: "#FFF" }}>
        <div id="booking-5" className="ck_menu_inner float_left">
          <div className="ck_logo_wrapper ck_logo_wrapper_inner">
            <img style={{ height: '80px' }} src={logo} alt="Logo" />
          </div>
          <div className="ck_navigation_right_wrapper">
            <div className="mainmenu d-xl-block d-lg-block d-md-block d-sm-none d-none">
              <ul className="main_nav_ul">
                <li className="has-mega gc_main_navigation">
                  <Link to="/" onClick={() => window.scrollTo(0, 0)}>Home</Link>
                </li>
                <li className="has-mega gc_main_navigation">
                  <a href="#">Products</a>
                  <ul>
                    <li><Link to="/Products" onClick={() => window.scrollTo(0, 0)}>Namkeen</Link></li>
                    <li><Link to="/SweetsCategory" onClick={() => window.scrollTo(0, 0)}>Sweets</Link></li>
                  </ul>
                </li>
                <li className="has-mega gc_main_navigation">
                  <Link to="/jainshreecontact" onClick={() => window.scrollTo(0, 0)}>Contact Us</Link>
                </li>
                <li><button onClick={showSidebar}>Menu</button></li>
              </ul>
            </div>

            {/* Cart & Sidebar */}
            <div className='cart-bar'>
              <div className='d-flex align-items-center' style={{ gap: '25px', position: 'relative' }}>
                {/* Cart Icon */}
                <Link to="/cart" onClick={() => window.scrollTo(0, 0)}>      
                  <li className="has-mega gc_main_navigation">
                    <a href="#" className="gc_main_navigation">
                      <BsCart3 color="#ff9930" size={20} />
                    </a>
                    <p className="counterred">{cart.length}</p>
                  </li> 
                </Link>
                {/* Sidebar Hamburger */}
                <div className="hamburger" onClick={showSidebar}>
                  <div className="hamburger__line hamburger__line--01">
                    <div className="hamburger__line-in hamburger__line-in--01"></div>
                  </div>
                  <div className="hamburger__line hamburger__line--02">
                    <div className="hamburger__line-in hamburger__line-in--02"></div>
                  </div>
                  <div className="hamburger__line hamburger__line--03">
                    <div className="hamburger__line-in hamburger__line-in--03"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Sidebar Navigation */}
        <Link to="#" className="menu-bars">
          <FaIcons.FaBars onClick={showSidebar} />
        </Link>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose color='#ffffff' />
              </Link>
            </li>
            {SideNavbarData.map((item, index) => (
              <li key={index} className={item.cName}>
                <Link to={item.path} onClick={() => window.scrollTo(0, 0)}>
                  {item.icon}
                  <span className='sidenavText'>{item.title}</span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
