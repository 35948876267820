import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { remove, updateQuantity } from '../../redux/CartSlice';
import { FaTrash } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Cart = () => {
  const [responseId, setResponseId] = React.useState("");
  const [responseState, setResponseState] = React.useState([]);
  const productitem = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const totalAmount = productitem.reduce((total, item) => total + (item.price * item.quantity), 0);

  const handleremove = (id) => {
    dispatch(remove(id));  // Remove item from the cart
    toast.success("Removed !", {
      position: "top-center"
    });
  };

  const handleUpdateQuantity = (id, quantity) => {
    const newQuantity = Math.max(1, parseInt(quantity, 10) || 1); // Ensure it’s at least 1
    dispatch(updateQuantity({ id, quantity: newQuantity }));  // Update quantity
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const createRazorpayOrder = (amount) => {
    let data = JSON.stringify({
      amount: amount * 100,
      currency: "INR"
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "http://localhost:5000/api/payment/orders",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        handleRazorpayScreen(response.data.amount);
      })
      .catch((error) => {
        console.log("error at", error);
      });
  };

  const handleRazorpayScreen = async (amount) => {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      alert("Some error at Razorpay screen loading");
      return;
    }

    const options = {
      key: 'rzp_test_2QNAVoN2x0Npk8',
      amount: amount,
      currency: 'INR',
      name: "Papaya Coders",
      description: "Payment to Papaya Coders",
      image: "https://papayacoders.com/demo.png",
      handler: function (response) {
        setResponseId(response.razorpay_payment_id);
      },
      prefill: {
        name: "Papaya Coders",
        email: "papayacoders@gmail.com"
      },
      theme: {
        color: "#F4C430"
      }
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const paymentFetch = (e) => {
    e.preventDefault();

    const paymentId = e.target.paymentId.value;

    axios.get(`http://localhost:5000/api/payment/payment/${paymentId}`)
      .then((response) => {
        console.log(response.data);
        setResponseState(response.data);
      })
      .catch((error) => {
        console.log("error occurs", error);
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="cart_product_wrapper" style={{ marginTop: '110px' }}>
  <div className="container">
    <div className="row">
      {/* Cart Products List */}
      <div className="col-lg-9 col-md-12 col-sm-12 col-12">
        <div className="btc_shop_single_prod_right_section">
          <h3>Your Cart Products ({productitem.length})</h3>
        </div>
        <div className="cart-table-wrapper">
          {productitem.map((item, index) => (
            <div className="cart-row" key={item.id}>
              <div className="cart-item">
                <div className="table_cart_img">
                  <figure>
                    <img src={item.image} alt={item.title} />
                  </figure>
                </div>
              </div>
              <div className="cart-item">
                <p>{item.title}</p>
              </div>
              <div className="cart-item">
                <p className="cart-page-price">{item.price}</p>
              </div>
              <div className="cart-item">
                <input
                  type="number"
                  value={item.quantity}
                  min="1"
                  onChange={(e) => handleUpdateQuantity(item.id, e.target.value)}
                />
              </div>
              <div className="cart-item">
                <p>{item.selectedWeight}</p>
              </div>
              <div className="cart-item">
                <p className="cart-page-total">{item.price * item.quantity}</p>
              </div>
              <div className="cart-item">
                <button onClick={() => handleremove(item.id)}>
                  <FaTrash color="red" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Checkout Section */}
      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
        <div className="shipping_Wrapper">
          <div className="btc_shop_single_prod_right_section">
            <h1>Estimate Shipping:</h1>
          </div>
          {/* Shipping Cost Breakdown */}
          <div className="estimate_shipping_wrapper">
            <table className="table">
              <tbody>
                <tr>
                  <th>Bill:</th>
                  <td>{totalAmount}</td>
                </tr>
                <tr>
                  <th>Discount:</th>
                  <td>0</td>
                </tr>
                <tr>
                  <th>Sub Total:</th>
                  <td>{totalAmount}</td>
                </tr>
              </tbody>
            </table>
            <div className="checkout-btn">
              <Link to="/checkout">
                <a href="#">Checkout</a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  );
};

export default Cart;
